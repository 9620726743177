@import '../SharedStyles/tooltip.scss';

@media screen and (max-width: 750px) {
  .cartIcon {
    width: 25px !important;
    height: 30px !important;
  }

  @media screen and (max-width: 630px) {
    .cartContainer {
      padding-right: 0 !important;
    }
  }

  .cartIconActive {
    width: 25px !important;
    height: 30px !important;
  }

  .itemsIncart {
    top: -35px !important;
  }
}

.productTop {
  margin-left: 10px;
  .menuBreadcrumbs {
    color: $textGray;
    font-family: DINPro;
    font-size: 12px;
    margin: 0;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-right: 10px;
  }

  .menuBreadcrumbs::first-letter {
    text-transform: uppercase;
  }

  .filtersAndCategory {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-right: 10px;

    .categoryText {
      color: $textBlue;
      font-family: DINPro;
      font-size: 36px;
      margin-bottom: 10px;
    }

    .spacingBetween {
      margin-bottom: 30px;
    }

    .filter {
      position: absolute;
      // right: 0;
      display: flex;
      bottom: 5px;
      margin: 0;
    }
  }
  .productCounter {
    color: $textGray;
    font-family: DINPro;
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.paddingCounter {
  padding-left: 10px !important;
  color: $textGray;
  font-size: 12px;
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.resultTopContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cartContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 15px;
  padding-top: 10px;
}

.cartIcon {
  width: 30px;
  height: 40px;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.cartIconActive {
  width: 30px;
  height: 40px;
  color: rgb(0, 0, 0);
}

.cartName {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: DINPro;
  text-align: center;
  letter-spacing: 0;
  opacity: 0.35;
  position: relative;
  top: -15px;
}

.cartNameActive {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: DINPro;
  text-align: center;
  letter-spacing: 0;
  position: relative;
  top: -15px;
}

.cartButtonContainer {
  background-color: transparent;
  border: 0;
}

.itemsIncart {
  background-color: #11448f;
  width: 24px;
  height: 18px;
  position: relative;
  top: -40px;
  right: -30px;
  border-radius: 10px;
}

.itemInCartNumber {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 12px;
  font-family: DINPro-Bold;
}

.emptyCartNumber {
  background-color: transparent;
  width: 16px;
  height: 18px;
  position: relative;
  top: -35px;
  right: -8px;
  border-radius: 8px;
}
