@import '../SharedStyles/tooltip.scss';

div.mobile {
  display: none;
}

div.desktop {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .expandPic {
    z-index: 41;
    padding: 10px;
    width: auto;
    height: auto;
    max-height: 400px !important;
    max-width: 450px;
    object-fit: cover;
  }

  div.mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    // min-height: 75px;
  }
  div.desktop {
    display: none !important;
  }
  .expandCard {
    flex-direction: column;
  }
  .expandCard {
    flex-direction: column;
  }
  .expandCardHalf {
    max-width: 100% !important;
    width: 100%;
    min-width: 100%;
    align-items: center;
    padding-right: 0 !important;
    /*     img {
      width: auto;
      max-height: 100%;
    } */
  }

  .expandCard-TextContainer {
    padding-right: 30px !important;
  }
  .dividerLine {
    width: 100%;
  }
  .closeIconContainer {
    display: none;
  }

  /*   .expandCardHalf.expandCardHalfImage {
    flex: 1 !important;
    overflow: hidden;
  }
  .expandCardHalf {
    flex: 2 !important;
    overflow-y: auto;
  } */

  .expandCardHalf.expandCardHalfImage {
    overflow: hidden;
    max-height: 600px;
  }
  .expandCardHalf {
    overflow-y: auto;
  }

  .productLayout .product .expandPicModal .expandPicModal-container {
    height: 100%;
    margin: 0;
    max-width: unset;
  }

  .expandCard-sizeContainer {
    max-height: unset !important;
  }

  div.expandCard {
    width: 100%;
    height: 100%;
  }
  .dividerLine {
    max-height: 1px;
  }
  div.expandCard-description {
    width: calc(100% - 15px);
  }

  div.expandCard-TextContainer {
    max-height: unset;
  }
  div.productCardModal-close {
    position: unset;
  }
  div {
    .expandCard-modelPrice {
      width: 100%;

      .lineThrough {
        text-decoration: line-through;
      }

      .sale {
        padding-right: 10px;
        color: $saleRed;
      }

      .normal {
        padding-right: 10px;
        color: black;
      }
    }
    .expandCard-descriptionName {
      width: 100%;
    }
    .expandCard-modelName {
      width: 100%;
    }
  }
}

@media screen and (max-width: 330px) {
  .lastUpdateTextInCard {
    font-size: 11px !important;
    align-self: flex-end;
    padding-right: 10px;
    padding-left: 5px;
  }
}

.productListModal {
  font-family: DINPro;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 1024px;
  z-index: 44;
}

.productListModal-container {
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  max-width: 830px;
  margin: 30px;
  width: 100%;
}

.productListModal-container-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: fit-content;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
}

.expandCard-SizeAndButtonsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.expandPic {
  z-index: 41;
  padding: 10px;
  width: auto;
  height: auto;
  max-height: 530px;
  max-width: 450px;
  object-fit: contain;
}

.expandPicPlaceholder {
  z-index: 41;
  max-height: 550px;
  max-width: 550px;
  padding: 10px;
  width: 100%;
  opacity: 0.5;
}

.closeIconContainer {
  float: right;
}

.expandPic-closeIconBorder {
  width: 40px;
  height: 40px;
  border: 1px solid #242b49;
  position: relative;
  top: -20px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
}
.exandPic-closeIcon {
  height: 50px;
  transform: rotate(45deg);
}

.expandCard {
  width: 900px;
  min-height: 550px;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0;
  display: flex;
}

.expandCardHalfImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expandCardHalf {
  max-width: 450px;
  flex: 1;
  position: relative;
  padding-right: 30px;
  padding-bottom: 15px;
}

.expandCard-TextContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  // max-height: 500px;
}

.expandCard-sizeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10px;
  overflow: auto;
  max-height: 280px;
}

.expandCard-sizeName {
  width: 360px;
  color: rgb(36, 43, 73);
  font-size: 18px;
  font-family: DINPro;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.dividerLine {
  background: rgb(229, 229, 229);
  border-radius: 0;
  height: 100%;
  min-width: 1px;
  min-height: 1px;
}

.expandCard-brandName {
  width: 243px;
  color: rgb(39, 38, 38);
  font-size: 14px;
  font-family: DINPro;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding-left: 15px;
  padding-top: 15px;
  text-transform: uppercase;
}

.expandCard-modelName {
  width: 360px;
  color: rgb(36, 43, 73);
  font-size: 18px;
  font-family: DINPro;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 5px;
  padding-left: 15px;
}

.expandCard-modelSKU {
  width: 360px;
  color: rgb(36, 43, 73);
  font-size: 14px;
  font-family: DINPro;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 5px;
  padding-left: 15px;
}

.expandCard-modelPrice {
  color: rgb(36, 43, 73);
  font-size: 32px;
  font-family: DINPro;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 15px;
  padding-top: 15px;

  .lineThrough {
    text-decoration: line-through;
  }

  .sale {
    padding-right: 10px;
    color: $saleRed;
  }

  .normal {
    padding-right: 10px;
    color: black;
  }
}
.expandCard-description {
  width: 420px;
  color: rgb(36, 43, 73);
  font-size: 14px;
  font-family: DINPro;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding-left: 15px;
  padding-top: 15px;
  flex: 1;
  overflow: auto;
  max-height: 100px;
}

.expandCard-descriptionName {
  width: 420px;
  color: rgb(36, 43, 73);
  font-size: 14px;
  font-family: DINPro;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding-left: 15px;
  padding-top: 15px;
}

.productListModal-body {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  overflow-y: auto;
  // max-height: 600px;
  height: 400px;
}

.bottomBorder {
  border-bottom: 5px solid rgb(229, 229, 229);
}

.productCardModal-close {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 10px !important;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
}

.productListModal-close:hover {
  font-weight: 700;
}

.sizeCard {
  padding: 5px;
  margin: 4px;
  background-color: whitesmoke;
  border-radius: 5px;
  border-width: 1px;
  outline: 0 !important;
}

.sizeAndAmountContainerInCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: rgb(255, 255, 255);
  margin-bottom: 3px;
  font-weight: bold;
}

.sizeCardInCard {
  flex: 1;
  padding: 5px;
  border-radius: 10px 0 0 10px;
  border: 1px solid #d8d8d8;
  padding-left: 20px;
  margin-right: 1px;
  font-weight: normal;
  font-family: DINPro;
}

.sizeCardInCardActive {
  flex: 1;
  padding: 5px;
  border-radius: 10px 0 0 10px;
  border: 1px solid rgb(17, 68, 143);
  background-color: rgb(17, 68, 143);
  color: white;
  font-weight: bold;
  padding-left: 20px;
  margin-right: 1px;
  font-family: DINPro;
}

.amountPerSizeInCard {
  text-align: center;
  max-width: 70px;
  border-width: 0;
  background: rgb(216, 216, 216);
  color: 'black';
  border: 1px solid rgb(216, 216, 216);
  border-radius: 0 10px 10px 0;
  font-family: DINPro;
  font-weight: normal;
}

.amountPerSizeInCardActive {
  text-align: center;
  max-width: 70px;
  border-width: 0;
  background: rgb(17, 68, 143);
  border: 1px solid rgb(17, 68, 143);
  color: rgb(255, 255, 255);
  border-radius: 0 10px 10px 0;
  font-family: DINPro;
  font-weight: bold;
}

.paddingLeft15 {
  padding-left: 15px;
}

.lastUpdateTextInCard {
  color: #717171;
  font-size: 12px;
  align-self: flex-end;
  padding-right: 15px;
}

.stockInCard {
  align-self: flex-end;
  color: black;
  font-family: DINPro;
  font-size: 14px;
}

.closeModal {
  position: fixed;
  border: 1px solid black;
  z-index: 13;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 1);
  opacity: 1;
  right: -20px;
  top: -20px;

  .closeIcon {
    transform: rotate(45deg);
    height: 50px;
  }

  .iconFiller {
    position: absolute;
    border-radius: 100%;
    opacity: 0;
    height: 100%;
    width: 100%;
    background-color: red;
    z-index: 14;
  }
}

.addToBasketButton {
  color: white;
  border-radius: 10px !important;
  border-width: 0;
  padding: 10px;
  // margin-left: 10px;
  margin-top: 20px;
  font-family: DINPro-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.addToBasketButtonText {
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: DINPro-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.productCardButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.uspsItemContainer {
  display: flex;
  flex-direction: row;
}

.uspsContainer {
  padding-left: 15px;
  padding-top: 5px;
}

.uspsItemText {
  font-size: 14px;
  padding-left: 2px;
}

.arrowStyleL {
  color: #ffffff;
  transform: rotate(90deg);
  float: right;
}

.arrowStyleLButton {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #024ea2;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-style: none;
  opacity: 0.5;
  left: 0;
}

.arrowStyleLButtonDeactivated {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #a2a2a2;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-style: none;
  opacity: 0.5;
  left: 0;
}

.arrowStyleLButton:hover {
  opacity: 1;
}

.arrowStyleR {
  color: #ffffff;
  transform: rotate(-90deg);
}

.arrowStyleRButton {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #024ea2;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-style: none;
  opacity: 0.5;
  right: 0;
}

.arrowStyleRButtonDeactivated {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #a2a2a2;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-style: none;
  opacity: 0.5;
  right: 0;
}

.arrowStyleRButton:hover {
  opacity: 1;
}

.noPaddingImage {
  padding-right: 0;
}

.customPagerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: white;
}

.fullCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #024ea2;
}

.fullCircleActive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #024ea2;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.carouselContainer {
  max-height: 500px;
}
