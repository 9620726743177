@import '../SharedStyles/tooltip.scss';

@media screen and (max-width: 1200px) {
  .saleMessage {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 950px) {
  .profile-icon-name-container {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    max-width: 100vw;
    div.category_1 {
      display: none;
    }
  }
}

@media screen and (max-width: 750px) {
  .saleMessage {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 630px) {
  .saleTextHome {
    display: none;
  }
  .saleMessage {
    font-size: 16px !important;
  }

  .flexRow {
    padding-right: 0 !important;
    margin-right: 10px !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .phoneNumber {
    font-size: 16px !important;
  }

  .saleMessage {
    font-size: 13px !important;
  }

  .disclaimerMessage {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 630px) {
  .logoAndSaleContainer {
    padding-bottom: 0 !important;
    padding-top: 10px !important;
    text-decoration-color: white !important;
  }
}

.navbar {
  max-width: 100vw;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 43;
  div.category_1 {
    display: none;
  }
}

.navbar {
  display: block !important;
  padding: 0 !important;

  .category_1 {
    float: left;
    width: 200px;
    min-width: 200px;
    background-color: $textBlue;
    color: $textWhite;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    transition-duration: 300ms;
    display: none !important;

    .text {
      color: $textWhite;
      font-family: DINPro;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      vertical-align: middle;
      margin-top: auto;
    }

    .icon {
      color: $textWhite;
    }
  }

  .main-top {
    height: 100%;
    width: 100%;
    font-family: DINPro;
    border-radius: 0;
    align-items: center;
    background-color: transparent;
    padding: 10px !important;

    .logo {
      padding-left: 10px;
      vertical-align: middle;
      width: 150px;
      height: 16px;
    }
    .logoSmall {
      vertical-align: middle;
      width: 18px;
      height: 18px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}

.logoAndSaleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration-color: white !important;
}

.saleTextHome {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: rgb(36, 43, 73);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-left: 10px;
}

.saleMessage {
  font-family: DINPro;
  font-size: 28px;
  color: black;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 10px;
}

.disclaimerMessage {
  font-family: DINPro;
  font-size: 12px;
  color: white;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 10px;
}

.marginStoreSelect {
  margin-left: 10px;
  align-self: center;
}

.phoneNumber {
  padding-left: 5px;
  font-size: 20px;
  color: white;
  font-family: DINPro;

  &:hover {
    color: white;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 50px;
  padding-left: 10px;
  padding-right: calc(15px / 2);
}

.cartIcon-topBar {
  width: 20px;
  height: 25px;
  color: rgb(0, 0, 0);
  opacity: 50%;
}

.cartIconActive-topBar {
  width: 20px;
  height: 25px;
  color: rgb(0, 0, 0);
}

.cartButtonContainer-topBar {
  background-color: transparent;
  border: 0;
  height: 0;
}

.itemsIncart-topbar {
  background-color: #11448f;
  width: 6px;
  height: 6px;
  position: relative;
  top: -25px;
  right: -15px;
  border-radius: 10px;
}

.itemInCartNumber-topbar {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 6px;
  font-family: DINPro-Bold;
}

.emptyCartNumber-topbar {
  background-color: transparent;
  width: 16px;
  height: 18px;
  position: relative;
  top: -35px;
  right: -8px;
  border-radius: 8px;
}

.topCartAndProfileContainer {
  display: flex;
  flex-direction: row;
}

.profile-icon-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-width: 0;
}

.profile-icon {
  background-color: rgb(230, 56, 74);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.profile-icon-letters {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: DINPro-Bold;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
}

.profile-icon-name-container {
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-profile-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border-width: 0;
}

.profile-dropdown-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 40px;
  right: 65px;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-dropdown-item {
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: DINPro;
  font-size: 18px;
  background-color: transparent;
  border-width: 0;
}

.profile-dropdown-item-notSelected {
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: DINPro;
  font-size: 18px;
  background-color: transparent;
  border-width: 0;
  text-align: start;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.profile-dropdown-item-bold {
  padding-right: 20px;
  font-family: DINPro;
  font-size: 18px;
  background-color: transparent;
  border-width: 0;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.profile-dropdown-item-top {
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: DINPro;
  font-size: 18px;
  background-color: transparent;
  border-width: 0;
  text-align: center;
}

.profile-dropdown-item-bottom {
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: DINPro;
  font-size: 18px;
  background-color: transparent;
  border-width: 0;
  text-align: center;
}

.iconDiv-profile {
  position: relative;
  width: 18px;
  display: flex;
  align-self: flex-end;
  z-index: 1;
  will-change: transform;
}

.top-clubs-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5e5e5;
  margin-left: 8px;
  margin-right: 8px;
}

.top-clubs-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.checkIcon {
  height: 16px;
  align-self: center;
  padding-left: 5px;
  padding-right: 3px;
}
