@import '../../components/SharedStyles/tooltip.scss';

.loginPageConatiner {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loginContainer {
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .loginHeaderText {
      font-family: DINPro-Medium;
      font-size: 36px;
      text-align: left;
      margin-bottom: 26px;
    }

    .loginInput {
      margin-bottom: 16px;
    }
    .loader {
      color: $white;
    }
    .disabledButtonWithNoStyle {
      background-color: $sanMarino;
    }
  }
}
