.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.MuiInputBase-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
  background-color: white !important;
  font-family: 'DINPro' !important;
}

.MuiInputBase-input:disabled {
  background-color: #d8d8d8 !important;
  font-family: 'DINPro' !important;
}
