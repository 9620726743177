@media screen and (max-width: 360px) {
  .modal-button-container {
    flex-direction: column !important;
  }

  .modal-button-container-cancel {
    padding-left: 0 !important;
  }
}

.modal-container {
  padding: 25px;
}

.modal-title-text {
  font-family: DINPro;
  font-size: 24px;
  font-weight: bold;
}

.modal-explanation-text {
  font-family: DINPro;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-button-container {
  padding-top: 10px;
}

.modal-button-container-cancel {
  padding-top: 10px;
  padding-left: 10px;
}

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
