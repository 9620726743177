@import '../../components/SharedStyles/tooltip.scss';

@media screen and (max-width: 768px) {
  .menu {
    display: none;
  }
  .productWrap {
    max-width: fit-content;
  }
}

.main-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.home {
  display: flex;
  overflow: hidden;
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
  backface-visibility: hidden;
  will-change: overflow;

  .menu {
    width: 200px;
    min-width: 200px;
    height: percentage($number: 1/1);
    overflow: auto;
    height: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  }

  .menu::-webkit-scrollbar {
    display: none;
  }

  .main {
    width: 100%;
    max-width: 100%;
    height: calc(100%);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    // padding-top: 10px ;
    padding-left: calc(15px / 2);
    padding-right: calc(15px / 2);

    .productWrap {
      display: flex;
      flex-wrap: wrap;
      width: calc(100%);
    }

    .filler {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
      width: 100%;
      height: 120px;
    }
  }

  .loadMoreContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .loadMoreButton {
    background-color: white;
    color: black;
    font-family: DINPro;
    align-self: center;
  }

  .loadMoreButton:hover {
    color: white;
  }
}

.noProductsText {
  font-family: DINPro;
  padding-left: 15px;
  color: #717171;
}
