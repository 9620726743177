@media screen and (max-width: 1140px) {
  .constact-info-block {
    width: 450px !important;
  }

  .my-info-buttom-submit-cancel {
    width: 450px !important;
  }

  .my-info-buttom-submit {
    width: 450px !important;
  }
}

@media screen and (max-width: 950px) {
  .constact-info-block {
    width: 350px !important;
  }

  .my-info-buttom-submit-cancel {
    width: 350px !important;
  }

  .my-info-buttom-submit {
    width: 350px !important;
  }
}

@media screen and (max-width: 750px) {
  .constact-info-text-block-wrap {
    flex-direction: column !important;
  }

  .myPage-button-container {
    flex-direction: column !important;
    height: 125px !important;
    justify-content: space-between;
  }

  .constact-info-block {
    width: 100% !important;
  }

  .my-info-buttom-submit-cancel {
    width: 100% !important;
  }

  .my-info-buttom-submit {
    width: 100% !important;
  }
}

.main-wrap {
  border-top: 3px solid #e5e5e5;
  height: 100%;
  //  height: 100%;
}

.constact-info-block {
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  width: 550px;
}

.constact-info-text-block-wrap {
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-info-input {
  border-width: 0;
  border-bottom-width: 1px;
  font-size: 12px !important;
  width: 100%;
  padding-bottom: 10px !important;
}

.myPage-button-container {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.my-info-buttom-submit {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 550px;
  margin-right: 15px;
}

.my-info-buttom-submit-cancel {
  border-width: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 550px;
  margin-left: 15px;
}

.edit-profile-container-show {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  height: 100%;
}

.edit-profile-container-hide {
  display: none;
}

.title-container {
  display: flex;
  flex-direction: row;
}

.expand-profile-container {
  background: transparent;
  border-width: 0;
  display: flex;
  flex-direction: row;
}

.expand-icon-profile {
  width: 24px;
  z-index: 1;
  will-change: transform;
}

.profile-button {
  padding-top: 5px;
}

.profile-page-title {
  font-family: DINPro;
  font-size: 24px;
  padding-top: 20px;
}

.profile-page-wrapper {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
