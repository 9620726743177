body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gellix';
  src: local('Gellix'),
    url('./styles/fonts/Gellix-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Gellix-Bold';
  src: local('Gellix-Bold'),
    url('./styles/fonts/Gellix-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'DINPro';
  src: local('DINPro'), url('./styles/fonts/DINPro tr.ttf') format('truetype');
}

@font-face {
  font-family: 'DINPro-Light';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro Light'),
    url('./styles/fonts/DINPro-Light tr.woff') format('woff');
}

@font-face {
  font-family: 'DINPro-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro Medium'),
    url('./styles/fonts/DINPro-Medium tr.woff') format('woff');
}

@font-face {
  font-family: 'DINPro-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro-Bold'),
    url('./styles/fonts/DINPro-Bold tr.woff') format('woff');
}

@font-face {
  font-family: 'DINPro-Black';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro-Black'),
    url('./styles/fonts/DINPro-Black tr.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-Bold'),
    url('./styles/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

:root {
  --toastify-color-error: #ff4761 !important;
  --toastify-color-success: #4de1b6 !important;
  --toastify-color-warning: #f6da6f !important;

  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
