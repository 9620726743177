@import '../../components/SharedStyles/tooltip.scss';

@media screen and (max-width: 1000px) {
  .subtitle-text {
    font-size: 17px !important;
  }

  .club-stats-wrapper {
    p {
      font-size: 16px !important;
    }
  }

  .title-text {
    font-size: 26px !important;
  }

  .title-text-history {
    font-size: 26px !important;
  }
}

.mainWrap {
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  /*  height: calc(100%); */
  /*   overflow-y: auto; */
  /*   -webkit-overflow-scrolling: touch; */
  /*   -ms-overflow-style: none; */
  height: 100%;
  overflow: scroll;
  padding-left: calc(30px / 2);
  padding-right: calc(30px / 2);
}

.title-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-text {
  font-family: DINPro;
  font-size: 32px;
}

.title-text-history {
  font-family: DINPro;
  font-size: 32px;
  padding-bottom: 15px;
}

.subtitle-text {
  font-family: DINPro;
  font-size: 18px;
  font-weight: bold;
}

.myPage-container {
  //height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  flex: 1;
}

.tabpanel-container {
  height: 100%;

  .tabpanel-wrapper {
    height: 100%;
  }
}
