@import '../SharedStyles/tooltip.scss';

@media screen and (max-width: 1024px) {
  div.productListModal-container {
    max-width: unset;
  }
  div.productListModal-header .closeModal {
    display: none;
  }
  div.productListModal-container {
    margin: 0;
    max-height: 100%;
  }
  div.productListModal-container-content {
    height: 100%;
  }
  div.productListModal-close {
    min-height: 75px;
  }
  div.productListModal-header {
    padding: 7px 15px;
    h1 {
      font-size: 18px;
      margin: 0;
    }
  }

  div.productListModal-title {
    padding: 0 30px;
  }

  div.productListModal-body {
    max-height: unset;
    height: 100%;
    padding: 0 15px;

    .productListModal-item {
      align-items: center;
      padding: 10px 0;
    }
    .productListModal-item-image {
      max-width: 50px;
      max-height: 50px;
    }
  }
  div.productListModal-totalPrice {
    padding: 15px;
    h1 {
      font-size: 24px;
    }
  }

  .contact-info-name-and-button {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .contact-info-error {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .contact-info-buttom-container {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .personalization-padding {
    padding-left: 0 !important;
  }

  .productListModal-item-left {
    flex: 1.3 !important;
  }

  .productListModal-item-right {
    flex: 2 !important;
  }

  .productListModal-title-item-left {
    flex: 1.3 !important;
    //  min-width: 180px;
  }

  .productListModal-title-item-right {
    flex: 2 !important;
    width: 160px;
  }

  .productListModal-item-description-toggleWord {
    display: none !important;
  }

  .item-size {
    display: none !important;
  }

  .productListModal-pers-title-item-right-size {
    flex: 0 !important;
  }

  .emptyItem-personalization {
    flex: 0 !important;
  }

  .personalizations-container-right {
    flex: 0 !important;
  }

  form.productListModal-email-form {
    flex-wrap: wrap;
    label {
      width: 100%;
      margin: 0;
    }
    input {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
    button {
      width: 100%;
      margin: 0;
      margin-top: 10px;
      padding: 12px 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .constact-info-text-block {
    max-width: 180px !important;
  }

  .OkMessage-container {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 600px) {
  .personalizations-container {
    flex-direction: column-reverse !important;
  }

  .personalizations-container-colored {
    flex-direction: column-reverse !important;
  }

  .product-list-personalizations-title {
    display: none !important;
  }

  .personalizations-container-left {
    flex-direction: column !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }

  .personalizations-container-right-personalizations {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .amount-container-personalization {
    flex: 0;
    justify-content: flex-start !important;
  }

  .trashIconContainer-personalization {
    flex: 1 !important;
  }

  .personalization-input {
    max-width: 100% !important;
  }

  .personalization-item-container {
    align-self: auto !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .personalization-input-collect-nr {
    max-width: 100% !important;
  }

  .order-info-line-mobile {
    display: flex !important;
    margin-left: 50px;
  }

  .productListModal-item-description {
    display: none !important;
    flex: 0 !important;
  }

  .productListModal-item-left {
    flex: 0 !important;
    min-width: none !important;
    justify-content: flex-start !important;
  }

  .productListModal-person-item {
    margin-left: 50px;
  }

  .amount-container {
    justify-content: flex-end !important;
  }

  .personalizations-container {
    border: #d8d8d8 0.5px solid !important;
    border-top: none !important;
  }

  .personalizations-container-colored {
    // border-bottom: none !important;
    border-right: #d8d8d8 0.5px solid;
    border-left: #d8d8d8 0.5px solid;
  }

  .add-new-personalization-button {
    flex-direction: column !important;
    align-items: flex-end !important;
  }

  .productListModal-title-item-left {
    flex: 2 !important;
  }

  .productListModal-item-text {
    padding-bottom: 15px;
  }

  .no-personalized-products-text {
    justify-content: flex-start !important;
    padding-left: 15px !important;
    font-size: 14px;
  }

  .contact-info-name-and-button {
    flex-direction: column !important;
  }

  .contact-info-buttom-container {
    flex-direction: column !important;
  }

  .contact-info-buttom-cancel {
    margin-left: 0 !important;
  }

  .constact-info-button {
    padding-left: 0 !important;
  }

  .add-new-personaliztion-text {
    margin: 0 !important;
  }

  // earlier in 470p

  .size-text {
    font-size: 14px !important;
    line-height: 15px !important;
    display: none !important;
  }

  .productListModal-title-item-right-size {
    display: none;
  }

  .productListModal-title-item-right-amount {
    display: none;
  }

  .size-text-mobile {
    display: flex !important;
  }

  .amount-number {
    font-size: 14px !important;
    padding: 12px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .contact-info-name {
    font-size: 18px !important;
  }

  .contact-info-error {
    padding: 5px;
    background-color: rgb(255, 123, 123);
    color: white;
    font-family: DINPro-Bold;
    margin: 2px;
    font-size: 12px;
  }

  .constact-info-text-block {
    max-width: 140px !important;
  }

  .contact-info-buttom-submit {
    font-size: 16px !important;
  }

  .contact-info-buttom-submit {
    margin-right: 10px !important;
  }

  .contact-info-section-title {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 400px) {
  div.productListModal-header {
    padding: 7px 15px;
    h1 {
      font-size: 14px;
      margin: 0;
    }
    button {
      font-size: 12px;
    }
  }
  div.productListModal-totalPrice h1 {
    font-size: 14px;
  }
  form.productListModal-email-form button {
    padding: 8px 12px;
  }
  div.productListModal-item
    .productListModal-item-description
    .productListModal-item-description-name {
    font-size: 14px;
  }
  .contact-info-buttom-submit {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

@media screen and (max-width: 360px) {
  .constact-info-text-block {
    max-width: 120px !important;
  }
}

@media screen and (max-width: 330px) {
  .quantity-icons {
    width: 20px !important;
    height: 20px !important;
  }

  .productListModal-item-text-container {
    padding-left: 5px !important;
  }

  .productListModal-body {
    padding: 0 10px !important;
  }
}

.productListModal {
  font-family: DINPro;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  z-index: 44;
}

.productListModal-container {
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  max-width: 830px;
  margin: 30px;
  width: 100%;
  max-height: 95%;
}

.productListModal-container-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // justify-content: space-between;
  // height: fit-content;
  overflow: auto;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
}

.productListModal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;
  padding: 30px;
  color: #242a4a;

  div {
    display: flex;
  }

  h1 {
    font-size: 28px;
    font-family: DINPro;
  }

  button {
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    font-size: 14px;
    margin-left: 45px;
    font-family: DINPro;
  }

  .closeModal {
    position: fixed;
    border: 1px solid black;
    z-index: 13;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    right: -20px;
    top: -20px;

    .closeIcon {
      transform: rotate(45deg);
      height: 50px;
    }

    .iconFiller {
      position: absolute;
      border-radius: 100%;
      opacity: 0;
      height: 100%;
      width: 100%;
      background-color: red;
      z-index: 14;
    }
  }
}

.productListModal-body {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 30px;
  overflow-y: auto;
  //max-height: 800px
  //  height: 400px;
}

.productListModal-title {
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  padding-right: 30px;

  .productListModal-title-item-left {
    flex: 2;
  }

  .productListModal-title-item-right {
    flex: 1.3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3px;

    div {
      font-size: 14px;
      font-weight: bold;
      font-family: DINPro-Bold;
      width: 39px;
      color: rgb(39, 38, 38);
      letter-spacing: 0;
      line-height: 21px;
      justify-content: center;
    }
  }
}

.productListModal-title-item-right-amount {
  flex: 2;
  text-align: center;
}

.productListModal-title-item-right-size {
  flex: 1;
  text-align: center;
}

.productListModal-item-wrapper {
  display: flex;
  flex-direction: column;
}

.productListModal-item {
  font-family: DINPro;
  border-top: 1.5px solid #747474;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: white;

  .productListModal-item-image {
    max-width: 50px;
    max-height: 50px;
  }

  .productListModal-item-text-container {
    padding-left: 15px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-around !important;

    .productListModal-item-text {
      flex: 1;
    }
  }

  .productListModal-item-description {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .productListModal-item-description-brand {
      font-family: DINPro;
      font-weight: bold;
      color: #272626;
      font-size: 12px;
    }

    .productListModal-item-description-name {
      color: #242b49;
      font-size: 16px;
    }

    .productListModal-item-description-size {
      color: #242b49;
      font-size: 14px;
    }

    .productListModal-item-description-toggleWord {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .productListModal-item-description-toggleWord-button {
      background-color: transparent;
      border-width: 0;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(17, 68, 143);
      font-size: 12px;
      font-family: DINPro-Bold;
      font-weight: bold;
      text-decoration: underline;
      letter-spacing: 0;
      line-height: 20px;
    }

    p {
      font-family: DINPro;
      margin: 0;
    }

    div {
      display: flex;
      flex-direction: column;
    }
  }

  .productListModal-item-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 2;
    // min-width: 180px;
  }

  .productListModal-dropdown-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    border-width: 0;
  }

  .productListModal-item-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    flex: 1.3;

    p {
      margin: 0;
      color: #000000;
      font-size: 22px;
      font-family: DINPro;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0;
    }

    .size-text {
      color: rgb(39, 38, 38);
      font-size: 16px;
      font-family: DINPro-Bold;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0;
      line-height: 21px;
      flex: 1;
    }

    .amount-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 2;

      .amount-number-container {
        background: rgb(216, 216, 216);
        border-radius: 5px;
        margin-left: 8px;
        margin-right: 8px;
        min-width: 40px;

        .amount-number {
          color: rgb(39, 38, 38);
          font-size: 16px;
          font-family: DINPro-Bold;
          font-weight: bold;
          text-align: center;
          letter-spacing: 0;
          line-height: 21px;
          padding: 8px;
        }
      }

      .adjustAmountContainer {
        border-width: 0;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .adjustAmountContainer:disabled {
        border-width: 0;
        background-color: transparent;
        opacity: 0.35;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
    .trashIcon {
      flex: 0.5;
    }
  }
}

.productListModal-package-dropdown {
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 0 !important;
  text-transform: uppercase;
  padding: 10px;

  .productListModal-package-dropdown-packageIcon {
    position: relative;
    left: -85px;

    .icon {
      height: 22px;
    }
  }

  .productListModal-package-dropdown-text {
    position: relative;
    left: -165px;
    color: rgb(36, 43, 73);
    font-family: DINPro;
    font-size: 18px;
    letter-spacing: 0.88px;
  }

  .productListModal-package-dropdown-delIcon {
    position: relative;
    left: 19px;
  }

  .productListModal-package-dropdown-price {
    font-family: DINPro;
    font-weight: bold;
    color: $saleRed;
    font-size: 22px;
    letter-spacing: 0;
  }
}

.bottomBorder {
  border-bottom: 5px solid rgb(229, 229, 229);
}

.productListModal-personalization-products {
  font-family: DINPro;
  /* 	overflow   : hidden; */

  .productListModal-person-item-inactive {
    border-top: 1px solid #e5e5e5;
    display: none;
    padding-bottom: 15px;
  }
  .productListModal-person-item {
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    // padding-top: 15px;
    // padding-bottom: 15px;

    .productListModal-item-image {
      max-width: 50px;
      max-height: 50px;
    }

    .productListModal-item-description {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .productListModal-item-description-brand {
        font-family: DINPro;
        color: #272626;
        font-size: 12px;
      }

      .productListModal-item-description-name {
        color: #242b49;
        font-size: 16px;
      }

      .productListModal-item-description-size {
        color: #242b49;
        font-size: 14px;
      }

      p {
        margin: 0;
      }

      div {
        display: flex;
        flex-direction: column;
      }
    }

    .productListModal-item-right {
      text-decoration: line-through;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: inherit;

      p {
        margin: 0;
        color: #000000;
        font-size: 22px;
        font-family: DINPro;
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0;
      }
    }
  }
}

.productListModal-totalPrice {
  font-family: DINPro;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 30px;
  max-height: 120px;

  h1 {
    font-size: 32px;
    color: #242a4a;
    margin: 0;
  }
}

.productListModal-close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  text-transform: uppercase;
  border-top: 1px solid #e5e5e5;
  flex: 1;
}

.productListModal-close:hover {
  font-weight: 700;
}

.productListModal-email-form {
  display: flex;
  height: auto;
  align-items: center;
  background-color: white;
  border-top: 1px solid #e5e5e5;
  padding: 15px;

  label {
    margin: 0;
    margin-right: 10px;
  }

  input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #cccccc;
  }

  button {
    border: 1px solid #242a4a;
    border-radius: 100px;
    padding: 8px 12px;
    margin-left: 20px;
    transition-duration: 0.2s;

    &:disabled {
      color: #cccccc;
      border-color: #cccccc;
    }
  }
}

.personalizations-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: #d8d8d8 0.5px solid;
}

.personalizations-container-colored {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f3f3f3;
  border-bottom: #d8d8d8 0.25px solid;
}

.personalizations-container-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 2;

  .personalization-item-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

  .personalization-input {
    flex: 1;
    max-width: 100px;
    height: 34px;
  }

  .personalization-input:disabled {
    max-width: 100px;
    height: 34px;
    flex: 1;
  }

  .personalization-input-collect-nr {
    flex: 1;
    max-width: 40px;
    height: 34px;
  }

  .personalization-input-collect-nr:disabled {
    max-width: 40px;
    height: 34px;
    flex: 1;
  }
}

.personalizations-container-right {
  flex: 1.3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item-size {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
  }

  .item-quantity {
    flex: 1;
    display: flex;
  }
}

.checkBox-personalization {
  position: relative;
}

.contact-info-container {
  margin-left: 30px;
  margin-right: 30px;
}

.contact-info-name-and-button {
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1.5px solid #747474;
}

.contact-info-name-and-error-container {
  display: flex;
  flex-direction: row;
}

.contact-info-name {
  color: rgb(36, 43, 73);
  font-size: 24px;
  font-family: DINPro;
  letter-spacing: 0;
}

.constact-info-button {
  color: rgb(17, 68, 143);
  font-size: 12px;
  font-family: DINPro-Bold;
  font-weight: bold;
  text-decoration: underline;
  text-align: right;
  letter-spacing: 0;
  line-height: 20px;
  border-width: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.contact-info-section-name {
  color: rgb(39, 38, 38);
  font-size: 14px;
  font-family: DINPro-Bold;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 21px;
}

.contact-info-section-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .contact-info-section-title {
    padding-top: 10px;
    color: rgb(39, 38, 38);
    font-size: 14px;
    font-family: DINPro-Bold;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 21px;
  }

  .constact-info-text-block {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    max-width: 220px;
  }

  .constact-info-text-title {
    color: rgb(36, 43, 73);
    font-size: 16px;
    font-family: DINPro-Medium;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .constact-info-text {
    color: rgb(36, 43, 73);
    font-size: 16px;
    font-family: DINPro;
    letter-spacing: 0;
    line-height: 28px;
  }

  .contact-info-section-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .contact-info-section-left-edit {
    flex: 1;
  }

  .contact-info-section-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-info-section-right-edit {
    flex: 1;
  }
}

.contact-info-buttom-container {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.contact-info-buttom-submit-container {
  flex: 1;

  .contact-info-buttom-submit {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    margin-right: 15px;
  }

  .contact-info-buttom-cancel {
    border-width: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    width: 100%;
    margin-left: 15px;
  }
}

.contact-info-input {
  border-width: 0;
  border-bottom-width: 1px;
  font-size: 12px !important;
  padding-bottom: 10px;
}

.contact-info-error {
  padding: 5px;
  background-color: rgb(255, 123, 123);
  color: white;
  font-family: DINPro-Bold;
  margin: 2px;
  margin-bottom: 15px;
  margin-right: 30px;
  margin-left: 30px;
}

.flex1 {
  flex: 1;
}

.flex05 {
  flex: 0.5;
}

.trashIconContainer {
  border-width: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0.5;
}

.trashIcon-wrapper {
  width: 22px;
  height: 22px;
  flex: 0.5 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.trashIconContainer-personalization {
  border-width: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.2;
  align-items: flex-end;
}

.trashIconContainer-personalization:disabled {
  border-width: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.2;
  opacity: 0.35;
  align-items: flex-end;
}

.emptyItem-personalization {
  flex: 2;
}

.size-text-mobile {
  color: rgb(39, 38, 38);
  font-size: 14px;
  font-family: DINPro-Medium;
  letter-spacing: 0;
  line-height: 20px;
  flex: 1;
  display: none;
}

.quantity-icons {
  width: 25px;
  height: 25px;
}

.OkMessage-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform-origin: 50% 50%;
  position: absolute;
}

.OkMessage-text {
  background-color: #07bc0c;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.okMessage-button {
  background-color: transparent;
  border-width: 0;
  color: white;
  text-decoration: underline;
}

.productListModal-wrapItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.contact-info-hide {
  flex: 0;
}

.contact-info-show {
  flex: 2;
}

.contact-info-wrapper {
  flex: 0;
}

.add-new-personalization-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.add-new-personaliztion-text {
  background-color: transparent;
  border-width: 0;
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(17, 68, 143);
  font-size: 12px;
  font-family: DINPro-Bold;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
}

.amount-container-personalization {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  .amount-number-container {
    background: rgb(216, 216, 216);
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px;
    min-width: 40px;

    .amount-number {
      color: rgb(39, 38, 38);
      font-size: 16px;
      font-family: DINPro-Bold;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0;
      line-height: 21px;
      padding: 8px;
    }
  }

  .adjustAmountContainer {
    border-width: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .adjustAmountContainer:disabled {
    border-width: 0;
    background-color: transparent;
    opacity: 0.35;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.product-list-personalizations-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.product-list-personalization-item-name {
  flex: 1;
  text-align: center;
}

.product-list-personalization-item-initials {
  flex: 1;
  text-align: center;
}

.product-list-personalization-item-number {
  flex: 1;
  text-align: center;
}

.product-list-personalizations-title {
  border-bottom: 1px solid rgb(229, 229, 229);
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
}

.product-list-personalization-numbers-to-text {
  padding-left: 5px;
  padding-right: 5px;
}

.productListModal-pers-title-item-right-size {
  flex: 1;
  text-align: center;
}

.personalizations-container-right-personalizations {
  flex: 1.3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item-size {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
  }

  .item-quantity {
    flex: 1;
    display: flex;
  }
}

.disabled-add-personalization-button {
  opacity: 0.35;
  background-color: transparent !important;
}

.no-personalized-products-text {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.order-info-line-mobile {
  display: none;
}

.productListModal-item-description-brand-mobile {
  font-family: DINPro;
  font-weight: bold;
  color: #272626;
  font-size: 12px;
}

.productListModal-item-description-name-mobile {
  color: #242b49;
  font-size: 14px;
}

.add-pers-button-mobile {
  background-color: transparent;
  border: none;
  font-family: DINPro-Bold;
  color: rgb(17, 68, 143);
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  margin-left: 0;
  padding-left: 0;
}
