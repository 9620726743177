@media screen and (max-width: 900px) {
  .stats-club-out-wrapper {
    flex-direction: column !important;
  }

  .stats-club-selector {
    justify-content: flex-start !important;
    align-self: flex-start !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
    padding-top: 25px;
  }
}

.order-wrap {
  border-top: 3px solid #e5e5e5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.title-container-order {
  display: flex;
  flex-direction: row;
}

.club-stats-error {
  padding: 5px;
  background-color: rgb(255, 123, 123);
  color: white;
  font-family: DINPro-Bold;
  margin: 2px;
  font-size: 12px;
}

.club-stats-wrapper {
  flex: 1;

  p {
    margin-bottom: 0.2rem;
    font-family: DINPro;
    font-size: 16px;
  }
}

.stats-club-out-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .stats-club-selector {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
  }
}

.club-stats-line {
  display: flex;
  flex-direction: row;
  font-family: DINPro;

  .line-value {
    font-weight: 700;
    padding-left: 5px;
    font-family: DINPro;
  }
}
