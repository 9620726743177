@media screen and (max-width: 1000px) {
  .new-per-title-bold {
    display: none !important;
  }

  .new-personalization-desktop {
    display: none !important;
  }

  .new-personalization-mobile {
    display: flex !important;
    flex-direction: column;
  }

  .personalization-item-new,
  .personalization-item-new-small {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .personalization-item-new,
  .personalization-item-new-small {
    display: flex;
    flex-direction: row;
  }

  .order-personalization-line {
    justify-content: center !important;
  }
}

@media screen and (max-width: 550px) {
  .personalization-item,
  .personalization-item-last {
    display: none !important;
  }
  .personalization-item-mobile,
  .personalization-item-last-mobile {
    display: flex !important;
  }

  .personalization-item-old-mobile {
    display: flex !important;
  }

  .personalization-item-old {
    display: none !important;
  }

  .order-all-personalization-items {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .personalizations-title-container {
    display: none !important;
  }

  .order-new-personalization-container-title-item,
  .order-all-personalization-items-title-item {
    font-size: 14px !important;
  }

  .personalization-item {
    font-size: 14px !important;
  }

  .personalization-item-new-str,
  .personalization-item-number-interval {
    font-size: 14px !important;
  }

  .order-all-personalization-items {
    font-size: 14px !important;
  }

  .order-item-row {
    font-size: 14px !important;
  }

  .personalization-item-new {
    font-size: 14px !important;
  }

  .personalization-item-new-small {
    font-size: 14px !important;
  }

  .line-old-value {
    font-size: 14px;
  }

  .line-old-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .order-item-row {
    font-size: 12px !important;
  }

  .order-item {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .order-item-no-border {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .order-all-personalization-items-title-container {
    font-size: 12px !important;
  }

  .order-all-personalization-items-item {
    font-size: 12px !important;
  }

  .order-personalization-line {
    max-width: 70px !important;
    word-wrap: break-word;
  }

  .order-personalization-line-numbers {
    max-width: 50px !important;
    word-wrap: break-word;
  }

  .order-personalization-line05flex {
    max-width: 50px !important;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 350px) {
  .order-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.order-wrap {
  border-top: 3px solid #e5e5e5;
  padding-top: 15px;
  height: 100%;
}

.title-container-order {
  display: flex;
  flex-direction: row;
}

.expand-order-container {
  background: transparent;
  border-width: 0;
  display: flex;
  flex-direction: row;
}

.expand-icon-orders {
  width: 24px;

  z-index: 1;
  will-change: transform;
}

.orders-show {
  display: flex;
  height: 100%;
  padding-top: 10px;
}

.orders-hide {
  display: none;
}

.order-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.order-item {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: DINPro;
}

.order-item-colored-background {
  background-color: #fafafa;
}

.order-item-no-border {
  border-width: 0 !important;
  border: 'none' !important;
}

.show-more-order-item {
  display: flex;
}

.hide-order-item {
  display: none;
}

.more-button-container {
  padding-top: 5px;
}

.order-item-left {
  flex: 1;
}

.order-item-right {
  flex: 1;
}

.order-item-info {
  margin: 3px;
}

.order-item-row {
  display: flex;
  flex-direction: row;
}

.personalizations-container-order {
  display: flex;
  flex-direction: column;
  font-family: DINPro;
  flex: 1;
  background-color: white;
}

.personalization-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.personalization-item-old {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;

  .order-personalization-line {
    justify-content: center;
  }
}

.personalization-item-old-mobile {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;

  .order-personalization-line {
    justify-content: center;
  }
}

.personalization-item-last {
  border-bottom: 'none' !important;
}

.personalization-itemwith-background {
  background-color: #fafafa;
}

.personalizations-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  border-top: 1px solid #e5e5e5;
}

.personalizations-title-item {
  flex: 1;
}

.personalizations-item-title {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  justify-content: center;
}

.personalizations-item-title-numbers {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  justify-content: center;
}

.personalizations-item-title05flex {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.order-personalization-line {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  max-width: 350px;
  display: flex;
}

.order-personalization-line-old-mobile {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  max-width: 350px;
  display: flex;

  .line-old-title {
    font-family: DINPro;
  }

  .line-old-value {
    font-family: DINPro;
    font-weight: 700;
    padding-left: 5px;
  }
}

.order-personalization-line-quantity {
  flex: 0.2;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  max-width: 70px;
}

.order-personalization-line-flex05 {
  flex: 0.2;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  max-width: 70px;
}

.order-personalization-line-numbers {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.order-personalization-line05flex {
  flex: 0.5;
  align-self: center;
  text-align: center;
  padding: 5px;
}

.order-personalization-line-container {
  display: flex;
  flex-direction: row;
}

.order-personalization-line-container-colored {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
}

.order-all-personalization-items {
  background-color: #f5f5f5;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.order-all-personalization-items-title-container {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
}

.order-all-personalization-items-title-item {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: center;
}

.order-all-personalization-items-title-itemFlex05 {
  flex: 0.5;
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: center;
}

.order-all-personalization-items-item {
  flex: 1;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

.order-all-personalization-items-itemFlex05 {
  flex: 0.5;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

.show-personalizations {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 5;
}

.hide-personalizations {
  display: none;
}

.toggle-personalizations-button {
  background-color: transparent;
  border-width: 0;
}

.noOrdersMessage {
  padding-top: 10px;
  font-family: DINPro;
  color: #808080;
}

.order-text-bold {
  font-weight: bold;
  font-family: DINPro;
  display: flex;
  justify-content: flex-start;
}

.order-new-personalization-container {
  // background-color: #F5F5F5;
}

.order-new-personalization-container-title-container {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  //  border-bottom: 1px solid #e5e5e5;
}

.order-new-personalization-container-title-item {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: center;
}

.order-item-container-numberfrom-and-to {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .from-and-to-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.order-item-personalization-new-container {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  // border-bottom: 1px solid #e5e5e5;
  border-top: 0.5px solid #e5e5e5;
  border-bottom: 0.5px solid #e5e5e5;

  .personalization-item-new-str {
    flex: 1;
    align-items: center;
    text-align: center;
  }

  .personalization-item-number-interval {
    flex: 1;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .number-interval-to-text {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.table-dark-line {
  background-color: #f5f5f5 !important;
}

.flex-placeholder-personalization-line {
  flex: 0.5;
}

.personliaziation-line-container {
  display: flex;
  flex-direction: row;
}

.personalisation-no-border {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.new-personalization-line-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
  flex: 5;

  .personalization-item-new {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .personalization-item-new-small {
    flex: 0.5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.new-personalization-line-container-mobile {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5e5e5;
  flex: 5;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;

  .personalization-item-new {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .personalization-item-new-small {
    flex: 0.5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.personalization-no-bottom-border {
  border-bottom: none !important;
}

.new-per-title-bold {
  font-weight: bold !important;
}

.new-pers-line-wrapper {
  display: flex;
  flex-direction: row;

  .pers-placeholder {
    flex: 0.2;
    max-width: 70px;
    align-self: center;
    padding-right: 10px;
    padding-left: 10px;
  }

  .pers-content-container {
    flex: 5;
  }
}

.new-personalization-desktop {
  display: flex;
  flex-direction: column;
}

.new-personalization-mobile {
  display: none;
}

.new-pers-mode-container {
  display: flex;
  flex-direction: column;
}

.new-pers-list-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.new-pers-vertical-cat-name-text {
  font-family: DINPro;
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}

.new-pers-vertical-cat-title-text {
  font-family: DINPro;
  display: flex;
  justify-content: flex-end;
}

.personalization-item-mobile {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.personalization-item-last-mobile {
  display: none;
  border-bottom: 'none' !important;
}

.order-personalization-line-mobile {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

.order-line-mobile {
  display: flex;
  flex-direction: row;
}
