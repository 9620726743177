@import '../components/SharedStyles/tooltip.scss';
.layoutWrapper {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;

  .layout {
    @include maxWidth;
    width: 100%;
    background-color: $textWhite;
    margin: 0 auto;
    padding: 0;
    height: 100%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 60px;
    margin-bottom: 5px;
  }
}
