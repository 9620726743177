@import '../SharedStyles/tooltip.scss';

@media screen and (max-width: 400px) {
  .lineThrough {
    font-size: 18px !important;
    padding-right: 3px;
  }
}

@media screen and (max-width: 768px) {
  .productLayout {
    width: calc(50% - 10.5px);
    min-width: unset !important;
    max-width: unset !important;

    &:nth-child(odd) {
      margin-left: 0;
      margin-right: 3px;
    }
    &:nth-child(even) {
      margin-right: 0;
      margin-left: 3px;
    }
  }
}

.load-more-products {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  button {
    background-color: #252b49;
    color: white;
    border: none;
    padding: 8px 14px;
    border-radius: 25px;
  }
}

.productLayout {
  // position     : relative;
  height: auto;
  max-width: 256px;
  min-width: 256px;
  // width        : calc(100% / 3 - 20px);
  margin-left: calc(15px / 2);
  margin-right: calc(15px / 2);
  margin-bottom: 15px;

  .product {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    float: left;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    margin-left: calc(15px / 2);
    margin-right: calc(15px / 2);
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    min-width: 145px;

    .figure {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      // justify-content: space-between;
    }

    .productWrapping {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .expandPicModal {
      position: fixed;
      z-index: 44;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.8);
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .expandPicModal-container {
        display: flex;
        flex-direction: column;
        transform: translateY(0);
        max-width: 900px;
        margin: 30px;
        margin-top: 5%;
        margin-left: 20%;
        margin-right: 20%;
        width: 100%;
      }
    }

    .imageDiv {
      //position       : relative;
      display: flex;
      // height         : 211px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /*  flex: 1; */
      margin-left: 5px;
      margin-right: 5px;

      .brand {
        color: $textBlack;
        font-family: DINPro;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 32px;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .model {
        color: $textBlue;
        font-family: DINPro;
        font-size: 15px;
        height: 72px;
        letter-spacing: 0;
        /*  line-height: 24px; */
        width: 100%;

        .models {
          .prod {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .image {
        max-width: 100%;
        max-height: 200px;
        height: auto;
      }

      .imagePlaceholder {
        max-width: 100%;
        max-height: 200px;
        height: auto;
        opacity: 0.5;
      }
    }

    .infoContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .price {
        margin-top: 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .lineThrough {
          color: black;
          font-size: 24px;
          height: 26px;
          letter-spacing: 0;
          line-height: 26px;
          text-decoration: line-through;
        }

        .sale {
          color: $saleRed;
          font-family: DINPro;
          font-weight: bold;
          font-size: 32px;
          font-weight: bold;
          height: 26px;
          letter-spacing: 0;
          line-height: 26px;
          margin-right: 10px;
        }

        .saleTitle {
          color: $saleRed;
          font-family: DINPro;
          font-weight: bold;
          font-size: 18px;
        }

        .normal {
          color: black;
          font-family: DINPro;
          font-size: 32px;
          font-weight: bold;
          height: 26px;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
  }
}

.toolTipAndStockContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stockContainer {
  align-items: flex-end;
}

.titleAndBrandCointainer {
  align-self: flex-start;
  flex: 1;
  min-height: 105px;
}

.stock {
  margin-top: 18px;
  margin-bottom: 5px;
  color: black;
  font-family: DINPro;
  font-size: 14px;
}

.questionMark {
  margin-top: 5px;
  margin-bottom: 5px;
  color: black;
  font-family: DINPro;
  font-size: 10px;
  background-color: whitesmoke;
  border-radius: 5px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.toolTipContainer {
  position: absolute;
  right: 30px;
  justify-content: flex-end;
  background-color: #535353;
  border-radius: 5px;
  width: 100px;
  flex-wrap: wrap;
  z-index: 40;
}

.toolTipText {
  color: white;
  font-family: DINPro;
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
}

.toggleSizeButton {
  background-color: white;
  border-radius: 5px;
  color: black;
  font-family: DINPro;
  font-size: 14px;
  margin-left: 0;
  margin-top: 10px;

  border-width: 1px;
  border-style: solid;
  border-color: black;
}
.sizesContainer {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.sizeCard {
  padding: 5px;
  margin: 4px;
  background-color: whitesmoke;
  border-radius: 5px;
  border-width: 1px;
  outline: 0 !important;
}

.notInStock {
  opacity: 0.5;
}

.buyButton-container {
  padding-bottom: 15px;
}

.buyButton {
  color: white;
  background-color: rgb(17, 68, 143);
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  border-width: 0;
  font-family: DINPro-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.productImageContainer {
  background-color: transparent;
  border-width: 0;
}
