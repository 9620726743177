@import '../SharedStyles/tooltip.scss';

@media screen and (min-width: 501px) {
  .mobileMeniu {
    display: none !important;
  }
}

.deactivateScroll {
  overflow: hidden !important;
}

.priceFilterPosition {
  right: 20px !important;
}

.filter {
  font-family: DINPro;
  align-items: center;
  font-size: 14px;
  display: flex;
  height: 35px;
  position: relative;
  max-height: 35px;

  .sort {
    position: relative;
    // min-width: fit-content;
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*    height: 35px;
    max-height: 35px; */

    .text {
      display: flex;
      justify-content: center;
      text-align: center;
      // margin: 10px;
      height: 100%;
    }

    .dropDown {
      position: absolute;
      top: 38px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $textWhite;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
      z-index: 10;
      overflow: auto;
      scrollbar-width: none;

      .dropDownItem {
        display: flex;
        flex-direction: row;
        text-align: flex-start;
        align-items: flex-start;
        padding-right: 10px;
        padding-left: 15px;
        align-items: flex-start;
        height: 50px;
        font-family: DINPro;
        font-size: 14px;
        letter-spacing: 0;
        justify-content: space-between;
      }

      .dropDownItemAll {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 15px;
        align-items: flex-start;
        height: 50px;
        font-family: DINPro;
        font-size: 14px;
        letter-spacing: 0;
        justify-content: space-between;
      }

      .picked {
        font-family: DINPro;
        font-weight: bold;
      }
    }

    .dropDown::-webkit-scrollbar {
      display: none;
    }

    .iconDiv {
      position: relative;
      width: 24px;
      display: flex;
      align-self: flex-end;
      z-index: 1;
      will-change: transform;
    }
  }
}

.dropDownLastItem {
  text-align: start;
}

.filterIcon {
  text-transform: uppercase;
  font-family: DINPro;
  height: 21px;
  align-items: center;
  font-size: 14px;
  height: 35px;
  position: relative;
  max-height: 35px;
}

.homePickerText {
  color: black !important;
  font-size: 14px !important;
  font-family: DINPro-Bold;
  width: 160px;
  justify-content: left !important;
  margin-right: 0 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  background-color: white;
  border-width: 0;
}

.pricePickerText {
  color: black !important;
  font-size: 14px !important;
  font-family: DINPro;
  width: 170px;
  text-align: right;
}

.meniuLayout {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dropDownWidth {
  width: 160px !important;
}

.priceDropDownWidth {
  display: flex;
}
