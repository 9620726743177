@import '../SharedStyles/tooltip.scss';

@media screen and (max-width: 750px) {
  .dropDown {
    margin-top: 0 !important;
  }

  .storePick {
    font-size: 16px !important;
  }

  .dropDownItem {
    font-size: 14px !important;
  }

  .mainIcon {
    width: 18px;
    top: 0;
  }
}

@media screen and (max-width: 450px) {
  .storePick {
    font-size: 16px !important;
  }
}

.filter {
  font-family: DINPro;
  align-items: center;
  font-size: 28px !important;
  color: black;
  display: flex;
  position: relative;

  .sort {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*     height: 35px;
    max-height: 35px; */
    color: black;

    .text {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 10px;
      height: 100%;
    }

    .dropDown {
      margin-top: 5px;
      overflow: scroll;
      background-color: $textWhite;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
      z-index: 42;
      max-height: 380px;
      min-width: 190px;

      .dropDownItem {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 15px;
        align-items: center;
        min-width: 190px !important;
        min-height: 55px !important;
        // min-height: fit-content !important;
        font-family: DINPro;
        font-size: 18px;
        letter-spacing: 0;
        justify-content: space-between;
        text-align: start;
        color: black;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
          cursor: pointer;
        }
      }

      .picked {
        font-family: DINPro;
        font-weight: bold;
      }
    }

    .iconDiv {
      position: relative;
      width: 18px;
      display: flex;
      align-self: flex-end;
      z-index: 1;
      will-change: transform;
    }

    .mainIcon {
      bottom: 10px !important;
      width: 24px;
    }
  }
}

.storePick {
  color: black !important;
  font-size: 36px;
  align-items: flex-end;
  margin: 0 !important;
  padding-bottom: 5px;
  background-color: transparent;
  border-width: 0;
}

.hideDropDown {
  display: none;
}
