@media screen and (max-width: 1000px) {
  .dropdown-info-text {
    font-size: 16px;
  }
}

.main-wrap-clubSelect {
  height: 100%;
  padding-top: 0.2rem;
}

.title-container-changeClub {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.change-club-container-show {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;
}

.change-club-container-hide {
  display: none;
}

.constact-info-text-block-wrap {
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dropDown-select-club {
  max-width: 700px;
  min-width: 200px;
}

.dropdown-info-text {
  font-family: DINPro;
  padding-bottom: 10px;
}

.downdown-club-container {
  padding-bottom: 30px;
}
